body {
  margin: 0;
  font-size: 14px;
  /* font-family: "Helvetica", sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* --secondary-bg: #ced6e0; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



h1 {
  margin-top: 0px;
  margin-bottom: 36px;
  font-family: readex pro, arial, sans-serifs;
  font-size: 48px;
  text-align: center;
  display: block;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

hr {
	border: none;
	height: 1px;
	color: #eee;
	background-color: #eee;
}

/*Class */
/* .content-container {
	text-align: center;
	margin: 24px 50px 12px;
} */

/* change autofill default setting */
/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
} */


/* show currently reading title */
li:hover .recent-reading .last-open-title {
	display: block;
	color: grey;
}



/* table */
.styled-table thead tr {
  background: #083d74;
  background-image:  linear-gradient(to right, #FDAB73, #AEC28F);
  color: #000000;
  text-align: left;
}

.styled-table th {
	color: #000000;
}

.styled-table th, .styled-table td{
	padding: 12px 15px;
	text-align: left;
}

.styled-table tbody tr:nth-of-type(even) {
	background-color: rgb(204, 204, 204);
}
.styled-table tbody tr:nth-of-type(odd) {
	background-color:#ecf0f1;
}